import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveDirective as _resolveDirective, normalizeStyle as _normalizeStyle, vModelCheckbox as _vModelCheckbox, vModelRadio as _vModelRadio, vModelText as _vModelText, vShow as _vShow } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "webSetupCot border-radius-20" }
const _hoisted_3 = { class: "webHead" }
const _hoisted_4 = { class: "webpage-header-div" }
const _hoisted_5 = {
  key: 0,
  class: "information-message-div"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "webHeadBtn"
}
const _hoisted_10 = ["src", "title"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 0,
  type: "button",
  class: "btn btn-primary btn-size-split dropdown-toggle dropdown-toggle-split margin-left-0",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  key: 1,
  class: "dropdown-menu"
}
const _hoisted_16 = { class: "dropdown-item cursor-pointer" }
const _hoisted_17 = { class: "dropdown-item cursor-pointer" }
const _hoisted_18 = {
  key: 0,
  class: "alert alert-success show-copied-message copy-success-message"
}
const _hoisted_19 = { class: "webContent" }
const _hoisted_20 = { class: "validation-summary-div" }
const _hoisted_21 = { class: "webThemeBlock" }
const _hoisted_22 = { class: "formLabel" }
const _hoisted_23 = { class: "primary-heading" }
const _hoisted_24 = { class: "formInput dvFlex" }
const _hoisted_25 = { class: "formInline" }
const _hoisted_26 = { class: "description-heading" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-md-8" }
const _hoisted_29 = { class: "form-group" }
const _hoisted_30 = { class: "bold-text" }
const _hoisted_31 = ["disabled"]
const _hoisted_32 = ["value"]
const _hoisted_33 = { class: "col-md-4" }
const _hoisted_34 = { class: "form-group" }
const _hoisted_35 = { class: "bold-text" }
const _hoisted_36 = ["disabled"]
const _hoisted_37 = ["value"]
const _hoisted_38 = { class: "row" }
const _hoisted_39 = { class: "margin-bottom-0 bold-text" }
const _hoisted_40 = { class: "dvFlex" }
const _hoisted_41 = { class: "form-group" }
const _hoisted_42 = { class: "description" }
const _hoisted_43 = { class: "form-group" }
const _hoisted_44 = { class: "description" }
const _hoisted_45 = { class: "row" }
const _hoisted_46 = { class: "bold-text" }
const _hoisted_47 = { class: "description" }
const _hoisted_48 = ["disabled"]
const _hoisted_49 = { class: "dvFlex" }
const _hoisted_50 = { class: "form-group" }
const _hoisted_51 = { class: "description" }
const _hoisted_52 = { class: "form-group" }
const _hoisted_53 = { class: "description" }
const _hoisted_54 = { class: "row" }
const _hoisted_55 = { class: "bold-text" }
const _hoisted_56 = { class: "dvFlex" }
const _hoisted_57 = { class: "form-group" }
const _hoisted_58 = { class: "description" }
const _hoisted_59 = { class: "form-group" }
const _hoisted_60 = { class: "description" }
const _hoisted_61 = { class: "row" }
const _hoisted_62 = { class: "bold-text" }
const _hoisted_63 = { class: "dvFlex" }
const _hoisted_64 = { class: "form-group" }
const _hoisted_65 = { class: "description" }
const _hoisted_66 = {
  key: 0,
  class: "webViewCot"
}
const _hoisted_67 = { class: "webViewBlock border-radius-10" }
const _hoisted_68 = { class: "navView" }
const _hoisted_69 = { class: "webThemeBlock" }
const _hoisted_70 = { class: "formLabel" }
const _hoisted_71 = ["innerHTML"]
const _hoisted_72 = { class: "formInput" }
const _hoisted_73 = { class: "webThemeBlock" }
const _hoisted_74 = { class: "formLabel" }
const _hoisted_75 = { class: "primary-heading" }
const _hoisted_76 = { class: "formInput" }
const _hoisted_77 = { class: "inlineInput dvFlex" }
const _hoisted_78 = { class: "form-group" }
const _hoisted_79 = ["disabled", "value"]
const _hoisted_80 = { class: "form-group" }
const _hoisted_81 = ["disabled", "value"]
const _hoisted_82 = { class: "form-group" }
const _hoisted_83 = { class: "description" }
const _hoisted_84 = ["disabled", "value"]
const _hoisted_85 = { class: "col-md-4" }
const _hoisted_86 = { class: "form-group" }
const _hoisted_87 = ["maxlength", "disabled"]
const _hoisted_88 = { class: "col-md-8" }
const _hoisted_89 = { class: "form-group" }
const _hoisted_90 = { class: "webThemeBlock" }
const _hoisted_91 = { class: "formLabel" }
const _hoisted_92 = { class: "primary-heading" }
const _hoisted_93 = { class: "formInput" }
const _hoisted_94 = { class: "margin-bottom-20" }
const _hoisted_95 = { class: "row" }
const _hoisted_96 = { class: "col-md-12" }
const _hoisted_97 = { class: "form-group position-relative div-showoverlay" }
const _hoisted_98 = { class: "description" }
const _hoisted_99 = ["disabled"]
const _hoisted_100 = { class: "col-md-12" }
const _hoisted_101 = { class: "form-group margin-top-0" }
const _hoisted_102 = ["maxlength", "disabled"]
const _hoisted_103 = { class: "col-md-4 margin-top-0 event-webpage-datepicker" }
const _hoisted_104 = { class: "col-md-4 margin-top-0 event-webpage-datepicker" }
const _hoisted_105 = { class: "col-md-12" }
const _hoisted_106 = { class: "form-group margin-top-10" }
const _hoisted_107 = { class: "description" }
const _hoisted_108 = ["disabled"]
const _hoisted_109 = { class: "col-md-12" }
const _hoisted_110 = { class: "form-group" }
const _hoisted_111 = ["maxlength", "disabled"]
const _hoisted_112 = { class: "webThemeBlock" }
const _hoisted_113 = { class: "formLabel" }
const _hoisted_114 = { class: "primary-heading" }
const _hoisted_115 = { class: "formInput" }
const _hoisted_116 = { class: "margin-bottom-0" }
const _hoisted_117 = { class: "chooseSection border-radius-10" }
const _hoisted_118 = { class: "checkboxBlock" }
const _hoisted_119 = ["disabled"]
const _hoisted_120 = { class: "formInline" }
const _hoisted_121 = { class: "form-group" }
const _hoisted_122 = ["maxlength", "placeholder", "disabled"]
const _hoisted_123 = { class: "margin-bottom-10 description" }
const _hoisted_124 = { class: "form-group" }
const _hoisted_125 = { class: "chooseSection border-radius-10" }
const _hoisted_126 = { class: "checkboxBlock" }
const _hoisted_127 = ["disabled"]
const _hoisted_128 = { class: "formInline" }
const _hoisted_129 = { class: "form-group" }
const _hoisted_130 = ["maxlength", "placeholder", "disabled"]
const _hoisted_131 = { class: "margin-bottom-10 description" }
const _hoisted_132 = { class: "margin-bottom-10 description" }
const _hoisted_133 = { class: "form-group margin-bottom-10" }
const _hoisted_134 = { class: "description" }
const _hoisted_135 = ["disabled"]
const _hoisted_136 = { class: "form-group margin-bottom-10" }
const _hoisted_137 = { class: "description" }
const _hoisted_138 = ["disabled"]
const _hoisted_139 = { class: "form-group margin-bottom-10" }
const _hoisted_140 = { class: "description" }
const _hoisted_141 = ["disabled"]
const _hoisted_142 = { class: "form-group margin-bottom-10" }
const _hoisted_143 = { class: "description" }
const _hoisted_144 = ["disabled"]
const _hoisted_145 = { class: "form-group margin-bottom-10" }
const _hoisted_146 = { class: "description" }
const _hoisted_147 = ["disabled"]
const _hoisted_148 = { class: "chooseSection border-radius-10" }
const _hoisted_149 = { class: "checkboxBlock" }
const _hoisted_150 = ["disabled"]
const _hoisted_151 = { class: "formInline" }
const _hoisted_152 = { class: "form-group" }
const _hoisted_153 = ["maxlength", "placeholder", "disabled"]
const _hoisted_154 = { class: "label-color font-weight-normal" }
const _hoisted_155 = { class: "label-color font-weight-normal" }
const _hoisted_156 = { class: "chooseSection border-radius-10" }
const _hoisted_157 = { class: "checkboxBlock" }
const _hoisted_158 = ["disabled"]
const _hoisted_159 = { class: "formInline" }
const _hoisted_160 = { class: "form-group" }
const _hoisted_161 = ["maxlength", "placeholder", "disabled"]
const _hoisted_162 = { class: "description margin-bottom-0" }
const _hoisted_163 = { class: "chooseSection border-radius-10" }
const _hoisted_164 = { class: "checkboxBlock" }
const _hoisted_165 = ["disabled"]
const _hoisted_166 = { class: "formInline" }
const _hoisted_167 = { class: "form-group" }
const _hoisted_168 = ["maxlength", "placeholder", "disabled"]
const _hoisted_169 = { class: "label-color font-weight-normal" }
const _hoisted_170 = { class: "chooseSection border-radius-10" }
const _hoisted_171 = { class: "checkboxBlock" }
const _hoisted_172 = ["disabled"]
const _hoisted_173 = { class: "formInline" }
const _hoisted_174 = { class: "form-group" }
const _hoisted_175 = ["maxlength", "placeholder", "disabled"]
const _hoisted_176 = { class: "label-color font-weight-normal" }
const _hoisted_177 = { class: "form-group margin-top-15" }
const _hoisted_178 = { class: "chooseSection border-radius-10" }
const _hoisted_179 = { class: "checkboxBlock" }
const _hoisted_180 = { class: "formInline" }
const _hoisted_181 = { class: "form-group" }
const _hoisted_182 = ["maxlength", "placeholder", "disabled"]
const _hoisted_183 = {
  key: 0,
  class: "sticky-bar"
}
const _hoisted_184 = {
  key: 1,
  class: "footer-tab sticky-bar border-radius-20"
}
const _hoisted_185 = { class: "parent-div-buttons" }
const _hoisted_186 = ["disabled"]
const _hoisted_187 = { class: "" }
const _hoisted_188 = ["disabled"]
const _hoisted_189 = {
  key: 5,
  class: "image-process"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_Toaster = _resolveComponent("Toaster")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_AnalyticsModalPopup = _resolveComponent("AnalyticsModalPopup")!
  const _component_DetailModalPopup = _resolveComponent("DetailModalPopup")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_Twitter = _resolveComponent("Twitter")!
  const _component_ImageContainer = _resolveComponent("ImageContainer")!
  const _component_AddURL = _resolveComponent("AddURL")!
  const _component_WarningMessageBar = _resolveComponent("WarningMessageBar")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_MapPicker = _resolveComponent("MapPicker")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    onKeypress: _cache[62] || (_cache[62] = 
//@ts-ignore
(...args) => (_ctx.IsPageDirty && _ctx.IsPageDirty(...args))),
    onClick: _cache[63] || (_cache[63] = 
//@ts-ignore
(...args) => (_ctx.IsPageDirty && _ctx.IsPageDirty(...args))),
    onMousemove: _cache[64] || (_cache[64] = 
//@ts-ignore
(...args) => (_ctx.IsPageDirty && _ctx.IsPageDirty(...args))),
    onTouchstart: _cache[65] || (_cache[65] = 
//@ts-ignore
(...args) => (_ctx.IsPageDirty && _ctx.IsPageDirty(...args)))
  }, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.showToaster)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Toaster, {
            message: _ctx.toasterMessage,
            "default-timeout": _ctx.toasterDefaultTimeout
          }, null, 8, ["message", "default-timeout"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showVueModalPopUp)
      ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
          key: 1,
          "modal-pop-up": _ctx.publishModalPopUp,
          onClose: _ctx.CloseVueModalPopUp,
          onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SaveAndPublish()))
        }, null, 8, ["modal-pop-up", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showAnalyticsModalPopup)
      ? (_openBlock(), _createBlock(_component_AnalyticsModalPopup, {
          key: 2,
          "analytics-modal-pop-up": _ctx.analyticsModalPopUp,
          onClose: _ctx.CloseAnalyticsModalPopup
        }, null, 8, ["analytics-modal-pop-up", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isPopupVisible)
      ? (_openBlock(), _createBlock(_component_DetailModalPopup, {
          key: 3,
          "detail-modal-popup-data": _ctx.detailModalPopupData,
          onPublish: _ctx.Publish,
          onClosePopup: _ctx.CloseDetailModalPopUp
        }, null, 8, ["detail-modal-popup-data", "onPublish", "onClosePopup"]))
      : _createCommentVNode("", true),
    (!_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["webSetupWrap", {'display-none': !_ctx.isDataLoaded}])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('EventWebpage.WebpageSetup')), 1),
                (!_ctx.isMobileView)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", {
                        class: "label-color font-weight-normal",
                        innerHTML: _ctx.GetInformationMessage()
                      }, null, 8, _hoisted_6),
                      (_ctx.eventWebPageData.EventWebPages.IsPublished)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            title: _ctx.$t('EventWebpage.CopyWebpageLink'),
                            class: "copy-icon-div cursor-pointer margin-left-5",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CopyPublicLinkToClipboard()))
                          }, [
                            _createElementVNode("img", { src: _ctx.copiedIcon }, null, 8, _hoisted_8)
                          ], 8, _hoisted_7))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isMobileView)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: _ctx.analyticsIcon,
                        class: "cursor-pointer",
                        title: _ctx.$t('EventWebpage.EventPageAnalytics.WebpageAnalytics'),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GetWebPageAnalytics()))
                      }, null, 8, _hoisted_10),
                      _createElementVNode("a", {
                        class: "btn btn-size-header-main preview-button btn-secondary",
                        target: "_blank",
                        href: _ctx.GetPreviewLink()
                      }, [
                        _createElementVNode("img", {
                          src: _ctx.previewIcon,
                          class: "margin-right-5"
                        }, null, 8, _hoisted_12),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('Preview')), 1)
                      ], 8, _hoisted_11),
                      _createElementVNode("div", {
                        class: _normalizeClass({'btn-group': _ctx.eventWebPageData.EventWebPages.IsPublished}),
                        style: {"display":"inline-flex !important"}
                      }, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["btn btn-primary btn-size-default width100 btn-create-event", {'split-publish-button': _ctx.eventWebPageData.EventWebPages.IsPublished}]),
                          "data-toggle": "modal",
                          "data-target": "#dvPublish",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ShowPopup()))
                        }, _toDisplayString(_ctx.$t('Publish')), 3),
                        (_ctx.eventWebPageData.EventWebPages.IsPublished)
                          ? (_openBlock(), _createElementBlock("button", _hoisted_13, [
                              _createElementVNode("img", { src: _ctx.dropDownIcon }, null, 8, _hoisted_14)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.eventWebPageData.EventWebPages.IsPublished)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createElementVNode("div", {
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Unpublish()))
                              }, [
                                _createElementVNode("a", _hoisted_16, _toDisplayString(_ctx.$t('Unpublish')), 1)
                              ]),
                              _createElementVNode("div", {
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowDetailModalPopUp()))
                              }, [
                                _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.$t('PublishSettings')), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (_ctx.showCopiedMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _cache[66] || (_cache[66] = _createElementVNode("i", {
                          class: "fa fa-check",
                          "aria-hidden": "true"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("LinkCopiedSuccessfully")), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                (_ctx.isValidationSummaryVisible)
                  ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                      key: 0,
                      "error-fields": _ctx.validationErrorFields,
                      onCloseClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                    }, null, 8, ["error-fields"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("Theme")), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("EventWebpage.SelectFontFamily")), 1),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t("FontFamily")), 1),
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.eventWebPageData.EventWebPageThemeSettings.FontFamily) = $event)),
                            class: "form-control selectBox",
                            disabled: _ctx.isMobileView
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventWebPageData.EventWebPageSupportedFonts, (font) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: font,
                                value: font
                              }, _toDisplayString(font), 9, _hoisted_32))
                            }), 128))
                          ], 8, _hoisted_31), [
                            [_vModelSelect, _ctx.eventWebPageData.EventWebPageThemeSettings.FontFamily]
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("Appearance")), 1),
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.eventWebPageData.EventWebPageThemeSettings.FontAppearance) = $event)),
                            class: "form-control selectBox",
                            disabled: _ctx.isMobileView
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fontAppearance, (appearance) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: appearance,
                                value: appearance
                              }, _toDisplayString(appearance), 9, _hoisted_37))
                            }), 128))
                          ], 8, _hoisted_36), [
                            [_vModelSelect, _ctx.eventWebPageData.EventWebPageThemeSettings.FontAppearance]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-7", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.$t("EventWebpage.PagePrimaryColors")), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-5", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("div", _hoisted_40, [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t("Background")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.eventWebPageData.EventWebPageThemeSettings.PrimaryBackgroundColor})
                            }, [
                              (!_ctx.isPaletteSelected[0])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.TogglePalette(0)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.PrimaryBackgroundColor,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.PrimaryBackgroundColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ]),
                          _createElementVNode("div", _hoisted_43, [
                            _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t("Font")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.eventWebPageData.EventWebPageThemeSettings.PrimaryFontColor})
                            }, [
                              (!_ctx.isPaletteSelected[1])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.TogglePalette(1)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.PrimaryFontColor,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.PrimaryFontColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ])
                        ])
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-7", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("label", _hoisted_46, _toDisplayString(_ctx.$t("EventWebpage.HeaderFooterAndAboutColors")), 1),
                        _createElementVNode("label", _hoisted_47, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.eventWebPageData.EventWebPageThemeSettings.AlwaysShowHeaderBackground) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.isMobileView
                          }, null, 8, _hoisted_48), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPageThemeSettings.AlwaysShowHeaderBackground]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.AlwaysShowHeaderBackground")), 1)
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-5", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("div", _hoisted_49, [
                          _createElementVNode("div", _hoisted_50, [
                            _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.$t("Background")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterBackgroundColor})
                            }, [
                              (!_ctx.isPaletteSelected[2])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.TogglePalette(2)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.HeaderFooterBackgroundColor,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.HeaderFooterBackgroundColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ]),
                          _createElementVNode("div", _hoisted_52, [
                            _createElementVNode("label", _hoisted_53, _toDisplayString(_ctx.$t("Font")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor})
                            }, [
                              (!_ctx.isPaletteSelected[3])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (_ctx.TogglePalette(3)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.HeaderFooterFontColor,
                                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.HeaderFooterFontColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ])
                        ])
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_54, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-7", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t("EventWebpage.ButtonAndAccentColors")), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-5", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("div", _hoisted_56, [
                          _createElementVNode("div", _hoisted_57, [
                            _createElementVNode("label", _hoisted_58, _toDisplayString(_ctx.$t("Background")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.eventWebPageData.EventWebPageThemeSettings.ButtonAccentBackgroundColor})
                            }, [
                              (!_ctx.isPaletteSelected[4])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (_ctx.TogglePalette(4)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.ButtonAccentBackgroundColor,
                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.ButtonAccentBackgroundColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ]),
                          _createElementVNode("div", _hoisted_59, [
                            _createElementVNode("label", _hoisted_60, _toDisplayString(_ctx.$t("Font")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.eventWebPageData.EventWebPageThemeSettings.ButtonAccentFontColor})
                            }, [
                              (!_ctx.isPaletteSelected[5])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[20] || (_cache[20] = _withModifiers(($event: any) => (_ctx.TogglePalette(5)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.ButtonAccentFontColor,
                                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.ButtonAccentFontColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ])
                        ])
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-7", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("label", _hoisted_62, _toDisplayString(_ctx.$t("LinkColor")), 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-5", {'width50': _ctx.isMobileView}])
                      }, [
                        _createElementVNode("div", _hoisted_63, [
                          _createElementVNode("div", _hoisted_64, [
                            _createElementVNode("label", _hoisted_65, _toDisplayString(_ctx.$t("Font")), 1),
                            _createElementVNode("div", {
                              class: "palette-container",
                              style: _normalizeStyle({'backgroundColor':_ctx.eventWebPageData.EventWebPageThemeSettings.LinkFontColor})
                            }, [
                              (!_ctx.isPaletteSelected[6])
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "optBlock",
                                    onClick: _cache[22] || (_cache[22] = _withModifiers(($event: any) => (_ctx.TogglePalette(6)), ["stop"]))
                                  }))
                                : _withDirectives((_openBlock(), _createBlock(_component_Twitter, {
                                    key: 1,
                                    modelValue: _ctx.LinkFontColor,
                                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.LinkFontColor) = $event))
                                  }, null, 8, ["modelValue"])), [
                                    [_directive_click_outside, _ctx.HideAllPalattes]
                                  ])
                            ], 4)
                          ])
                        ])
                      ], 2)
                    ])
                  ]),
                  (!_ctx.isMobileView)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                        _createElementVNode("div", _hoisted_67, [
                          _createElementVNode("div", {
                            class: "headerView",
                            style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterBackgroundColor})
                          }, [
                            _cache[67] || (_cache[67] = _createElementVNode("i", { class: "fa fa-picture-o" }, null, -1)),
                            _createElementVNode("div", _hoisted_68, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxNavBarItems, (item, index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: index,
                                  class: "viewLine",
                                  style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor})
                                }, null, 4))
                              }), 128))
                            ]),
                            _createElementVNode("span", {
                              class: "headerBtnView",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.ButtonAccentBackgroundColor})
                            }, [
                              _createElementVNode("span", {
                                class: "viewLine sp-full",
                                style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.ButtonAccentFontColor})
                              }, null, 4)
                            ], 4)
                          ], 4),
                          _cache[68] || (_cache[68] = _createElementVNode("div", { class: "bannerView" }, [
                            _createElementVNode("i", { class: "fa fa-picture-o" })
                          ], -1)),
                          _createElementVNode("div", {
                            class: "aboutView",
                            style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterBackgroundColor})
                          }, [
                            _createElementVNode("span", {
                              class: "viewLine",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor})
                            }, null, 4),
                            _createElementVNode("span", {
                              class: "viewLine sp-full",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor})
                            }, null, 4),
                            _createElementVNode("span", {
                              class: "viewLine sp-full",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor})
                            }, null, 4),
                            _createElementVNode("span", {
                              class: "viewLine sp-half",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor})
                            }, null, 4)
                          ], 4),
                          _createElementVNode("div", {
                            class: "bottomView",
                            style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.PrimaryBackgroundColor})
                          }, [
                            _createElementVNode("span", {
                              class: "viewLine",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.PrimaryFontColor})
                            }, null, 4),
                            _createElementVNode("span", {
                              class: "viewLine sp-full",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.PrimaryFontColor})
                            }, null, 4),
                            _createElementVNode("span", {
                              class: "viewLine sp-full",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.PrimaryFontColor})
                            }, null, 4),
                            _createElementVNode("span", {
                              class: "viewLine sp-half",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.PrimaryFontColor})
                            }, null, 4)
                          ], 4),
                          _createElementVNode("div", {
                            class: "footerView",
                            style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterBackgroundColor})
                          }, [
                            _createElementVNode("span", {
                              class: "viewLine sp-half",
                              style: _normalizeStyle({'backgroundColor': _ctx.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor})
                            }, null, 4)
                          ], 4)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_69, [
                _createElementVNode("div", _hoisted_70, [
                  _createElementVNode("label", {
                    class: "primary-heading",
                    innerHTML: _ctx.$t('EventWebpage.Logo')
                  }, null, 8, _hoisted_71)
                ]),
                _createElementVNode("div", _hoisted_72, [
                  _createVNode(_component_ImageContainer, {
                    "image-container-data": _ctx.eventPageLogoData,
                    onImageContainerClicked: _cache[24] || (_cache[24] = ($event: any) => (_ctx.ClickOnImage(true)))
                  }, null, 8, ["image-container-data"])
                ])
              ]),
              _createElementVNode("div", _hoisted_73, [
                _createElementVNode("div", _hoisted_74, [
                  _createElementVNode("label", _hoisted_75, _toDisplayString(_ctx.$t("Button")), 1)
                ]),
                _createElementVNode("div", _hoisted_76, [
                  _createElementVNode("div", _hoisted_77, [
                    _createElementVNode("div", _hoisted_78, [
                      _createElementVNode("label", {
                        class: _normalizeClass([{'disabled-font': _ctx.eventWebPageData.InviteUrl === null}, "description"]),
                        onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.SetVentlaInviteLink()))
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType) = $event)),
                          type: "radio",
                          name: "selectLinkType",
                          disabled: _ctx.isMobileView || _ctx.eventWebPageData.InviteUrl === null,
                          value: _ctx.selectedButtonType.VentlaInviteLink
                        }, null, 8, _hoisted_79), [
                          [_vModelRadio, _ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType]
                        ]),
                        _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.UseVentlaInviteLink")), 1)
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_80, [
                      _createElementVNode("label", {
                        class: "description",
                        onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.SetExternalLink()))
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType) = $event)),
                          type: "radio",
                          name: "selectLinkType",
                          disabled: _ctx.isMobileView,
                          value: _ctx.selectedButtonType.ExternalLink
                        }, null, 8, _hoisted_81), [
                          [_vModelRadio, _ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType]
                        ]),
                        _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.UseExternalLink")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_82, [
                      _createElementVNode("label", _hoisted_83, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType) = $event)),
                          type: "radio",
                          name: "selectLinkType",
                          disabled: _ctx.isMobileView,
                          value: _ctx.selectedButtonType.NoAction
                        }, null, 8, _hoisted_84), [
                          [_vModelRadio, _ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType]
                        ]),
                        _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.NoButton")), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["row", {'display-none': _ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType === _ctx.selectedButtonType.NoAction}])
                  }, [
                    _createElementVNode("div", _hoisted_85, [
                      _createElementVNode("div", _hoisted_86, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t("ButtonTitle")), 1),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonTitle) = $event)),
                          type: "text",
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateInputField(_ctx.$t('Title'),_ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonTitle === '', true)}]),
                          maxlength: _ctx.maxTitleLength,
                          disabled: _ctx.isMobileView
                        }, null, 10, _hoisted_87), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonTitle]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_88, [
                      _createElementVNode("div", _hoisted_89, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t("URL")), 1),
                        _createElementVNode("div", {
                          class: _normalizeClass({'border-red': _ctx.ValidateInputField(_ctx.$t('InvalidUrl'),_ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonURL === '' && _ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonType === _ctx.selectedButtonType.ExternalLink, true)})
                        }, [
                          _createVNode(_component_AddURL, {
                            "is-disabled": _ctx.DisableCTAButtonUrl(),
                            "placeholder-text": _ctx.$t('URL'),
                            "item-value": _ctx.eventWebPageData.EventWebPagesMetaData.CTAButtonURL,
                            "is-required": _ctx.isUrlRequired,
                            onIsValidUrl: _cache[31] || (_cache[31] = ($event: any) => (_ctx.IsValidUrl($event))),
                            onUrl: _cache[32] || (_cache[32] = ($event: any) => (_ctx.SetUrl($event)))
                          }, null, 8, ["is-disabled", "placeholder-text", "item-value", "is-required"])
                        ], 2)
                      ])
                    ])
                  ], 2),
                  (_ctx.IsInviteePublished())
                    ? (_openBlock(), _createBlock(_component_WarningMessageBar, {
                        key: 0,
                        class: "margin-right-15",
                        "information-view-model": _ctx.inviteeWarningMessage
                      }, null, 8, ["information-view-model"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_90, [
                _createElementVNode("div", _hoisted_91, [
                  _createElementVNode("label", _hoisted_92, _toDisplayString(_ctx.$t("Banner")), 1)
                ]),
                _createElementVNode("div", _hoisted_93, [
                  _createElementVNode("div", _hoisted_94, [
                    _createVNode(_component_ImageContainer, {
                      "image-container-data": _ctx.eventPageBannerImageData,
                      onImageContainerClicked: _cache[33] || (_cache[33] = ($event: any) => (_ctx.ClickOnBackgroundImage(false,false)))
                    }, null, 8, ["image-container-data"])
                  ]),
                  _createElementVNode("div", _hoisted_95, [
                    _createElementVNode("div", _hoisted_96, [
                      _createElementVNode("div", _hoisted_97, [
                        _createElementVNode("label", _hoisted_98, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowOverlayOnBannerImage) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.isMobileView
                          }, null, 8, _hoisted_99), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowOverlayOnBannerImage]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.AddOverlayOnBannerImage")), 1)
                        ]),
                        _createElementVNode("i", {
                          class: "fa fa-info-circle overlay-info",
                          "aria-hidden": "true",
                          onMouseover: _cache[35] || (_cache[35] = ($event: any) => (_ctx.getInformationString(true))),
                          onMouseleave: _cache[36] || (_cache[36] = ($event: any) => (_ctx.getInformationString(false)))
                        }, null, 32),
                        _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t('EventWebpage.AddOverlayOnBannerImageInformation')), 513), [
                          [_vShow, _ctx.showOverlayInfo]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_100, [
                      _createElementVNode("div", _hoisted_101, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventWebpage.BannerText")), 1),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.BannerText) = $event)),
                          type: "text",
                          maxlength: _ctx.maxInputFieldLength,
                          class: "form-control",
                          disabled: _ctx.isMobileView
                        }, null, 8, _hoisted_102), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.BannerText]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_103, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t("StartDate")), 1),
                      _createVNode(_component_Datepicker, {
                        date: _ctx.GetStartDate(),
                        disable: _ctx.isMobileView,
                        "date-only": true,
                        "id-value": "eventWebpageStartDate",
                        onSelectedDate: _ctx.SetSelectedStartDate
                      }, null, 8, ["date", "disable", "onSelectedDate"])
                    ]),
                    _createElementVNode("div", _hoisted_104, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t("EndDate")), 1),
                      _createVNode(_component_Datepicker, {
                        date: _ctx.GetEndDate(),
                        disable: _ctx.isMobileView,
                        "date-only": true,
                        mindatetime: _ctx.minDateTime,
                        "id-value": "eventWebpageEndDate",
                        onSelectedDate: _ctx.SetSelectedEndDate
                      }, null, 8, ["date", "disable", "mindatetime", "onSelectedDate"])
                    ]),
                    _createElementVNode("div", _hoisted_105, [
                      _createElementVNode("div", _hoisted_106, [
                        _createElementVNode("label", _hoisted_107, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.HideDates) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.isMobileView
                          }, null, 8, _hoisted_108), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.HideDates]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.HideDateOnWebpage")), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_109, [
                      _createElementVNode("div", _hoisted_110, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventWebpage.VenueName")), 1),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.BannerVenueName) = $event)),
                          type: "text",
                          maxlength: _ctx.maxInputFieldLength,
                          class: "form-control",
                          disabled: _ctx.isMobileView
                        }, null, 8, _hoisted_111), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.BannerVenueName]
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_112, [
                _createElementVNode("div", _hoisted_113, [
                  _createElementVNode("label", _hoisted_114, _toDisplayString(_ctx.$t("EventWebpage.PageSections")), 1)
                ]),
                _createElementVNode("div", _hoisted_115, [
                  _createElementVNode("label", _hoisted_116, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.$t("EventWebpage.PageSectionHeader")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_117, [
                    _createElementVNode("div", _hoisted_118, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowAboutSection) = $event)),
                        type: "checkbox",
                        disabled: _ctx.isMobileView
                      }, null, 8, _hoisted_119), [
                        [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowAboutSection]
                      ]),
                      _cache[69] || (_cache[69] = _createElementVNode("label", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_120, [
                      _createElementVNode("div", _hoisted_121, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.AboutSectionTitle) = $event)),
                          type: "text",
                          maxlength: _ctx.maxTitleLength,
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateInputField(_ctx.$t('Title'),_ctx.eventWebPageData.EventWebPagesMetaData.AboutSectionTitle === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowAboutSection)}]),
                          placeholder: _ctx.$t('About'),
                          disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowAboutSection)
                        }, null, 10, _hoisted_122), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.AboutSectionTitle]
                        ])
                      ]),
                      _createElementVNode("label", _hoisted_123, _toDisplayString(_ctx.$t("EventWebpage.AboutDescription")), 1),
                      _createElementVNode("div", _hoisted_124, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t("Description")), 1),
                        _createVNode(_component_Editor, {
                          description: _ctx.eventWebPageData.EventWebPagesMetaData.AboutSectionDescription,
                          "is-read-only": _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowAboutSection),
                          class: _normalizeClass({'border-red': _ctx.ValidateInputField(_ctx.$t('Description'),_ctx.eventWebPageData.EventWebPagesMetaData.AboutSectionDescription === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowAboutSection)}),
                          onUpdatedDescription: _ctx.SetAboutDescription
                        }, null, 8, ["description", "is-read-only", "class", "onUpdatedDescription"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_125, [
                    _createElementVNode("div", _hoisted_126, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection) = $event)),
                        type: "checkbox",
                        disabled: _ctx.isMobileView
                      }, null, 8, _hoisted_127), [
                        [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection]
                      ]),
                      _cache[70] || (_cache[70] = _createElementVNode("label", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_128, [
                      _createElementVNode("div", _hoisted_129, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionTitle) = $event)),
                          type: "text",
                          maxlength: _ctx.maxTitleLength,
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateInputField(_ctx.$t('Title'),_ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionTitle === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection)}]),
                          placeholder: _ctx.$t('Schedule'),
                          disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection)
                        }, null, 10, _hoisted_130), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionTitle]
                        ])
                      ]),
                      _createElementVNode("label", _hoisted_131, _toDisplayString(_ctx.$t("EventWebpage.ScheduleDescription")), 1),
                      _createElementVNode("label", _hoisted_132, _toDisplayString(_ctx.$t("EventWebpage.ScheduleSubDescription")), 1),
                      _createElementVNode("div", _hoisted_133, [
                        _createElementVNode("label", _hoisted_134, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowEndTime) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection)
                          }, null, 8, _hoisted_135), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowEndTime]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.ShowEndTime")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_136, [
                        _createElementVNode("label", _hoisted_137, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowDescription) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection)
                          }, null, 8, _hoisted_138), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowDescription]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.ShowDescription")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_139, [
                        _createElementVNode("label", _hoisted_140, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowTracks) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection)
                          }, null, 8, _hoisted_141), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowTracks]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.ShowTracks")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_142, [
                        _createElementVNode("label", _hoisted_143, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowTags) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection)
                          }, null, 8, _hoisted_144), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowTags]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.ShowTags")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_145, [
                        _createElementVNode("label", _hoisted_146, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowProgramSpeakers) = $event)),
                            type: "checkbox",
                            class: "checkbox-alignment",
                            disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowScheduleSection)
                          }, null, 8, _hoisted_147), [
                            [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ScheduleSectionShowProgramSpeakers]
                          ]),
                          _createTextVNode(_toDisplayString(_ctx.$t("EventWebpage.ShowProgramSpeakers")), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_148, [
                    _createElementVNode("div", _hoisted_149, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowSpeakers) = $event)),
                        type: "checkbox",
                        disabled: _ctx.isMobileView
                      }, null, 8, _hoisted_150), [
                        [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowSpeakers]
                      ]),
                      _cache[71] || (_cache[71] = _createElementVNode("label", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_151, [
                      _createElementVNode("div", _hoisted_152, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.SpeakerSectionTitle) = $event)),
                          type: "text",
                          maxlength: _ctx.maxTitleLength,
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateInputField(_ctx.$t('Title'),_ctx.eventWebPageData.EventWebPagesMetaData.SpeakerSectionTitle === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowSpeakers)}]),
                          placeholder: _ctx.$t('Speakers'),
                          disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowSpeakers)
                        }, null, 10, _hoisted_153), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.SpeakerSectionTitle]
                        ])
                      ]),
                      _createElementVNode("p", _hoisted_154, _toDisplayString(_ctx.$t("EventWebpage.SpeakerDescription")), 1),
                      _createElementVNode("p", _hoisted_155, [
                        _createElementVNode("em", null, _toDisplayString(_ctx.$t("EventWebpage.SpeakerSubDescription")), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_156, [
                    _createElementVNode("div", _hoisted_157, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowVenue) = $event)),
                        type: "checkbox",
                        disabled: _ctx.isMobileView
                      }, null, 8, _hoisted_158), [
                        [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowVenue]
                      ]),
                      _cache[72] || (_cache[72] = _createElementVNode("label", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_159, [
                      _createElementVNode("div", _hoisted_160, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.VenueSectionTitle) = $event)),
                          type: "text",
                          maxlength: _ctx.maxTitleLength,
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateInputField(_ctx.$t('Title'),_ctx.eventWebPageData.EventWebPagesMetaData.VenueSectionTitle === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowVenue)}]),
                          placeholder: _ctx.$t('Venue'),
                          disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowVenue)
                        }, null, 10, _hoisted_161), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.VenueSectionTitle]
                        ])
                      ]),
                      _createElementVNode("label", _hoisted_162, _toDisplayString(_ctx.$t("EventWebpage.VenuDescription")), 1),
                      _createVNode(_component_MapPicker, {
                        "selected-item": _ctx.eventWebPageData.EventWebPagesMetaData,
                        "show-heading": false,
                        "hide-map": true,
                        "is-read-only": _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowVenue),
                        "is-city-required": _ctx.ValidateInputField(_ctx.$t('City'),_ctx.eventWebPageData.EventWebPagesMetaData.City === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowVenue),
                        "is-street-address-required": _ctx.ValidateInputField(_ctx.$t('StreetAddress'),_ctx.eventWebPageData.EventWebPagesMetaData.Address === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowVenue),
                        class: "margin-bottom-15"
                      }, null, 8, ["selected-item", "is-read-only", "is-city-required", "is-street-address-required"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_163, [
                    _createElementVNode("div", _hoisted_164, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowSponsors) = $event)),
                        type: "checkbox",
                        disabled: _ctx.isMobileView
                      }, null, 8, _hoisted_165), [
                        [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowSponsors]
                      ]),
                      _cache[73] || (_cache[73] = _createElementVNode("label", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_166, [
                      _createElementVNode("div", _hoisted_167, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.SponsorSectionTitle) = $event)),
                          type: "text",
                          maxlength: _ctx.maxTitleLength,
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateInputField(_ctx.$t('Title'),_ctx.eventWebPageData.EventWebPagesMetaData.SponsorSectionTitle === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowSponsors)}]),
                          placeholder: _ctx.$t('Sponsors'),
                          disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowSponsors)
                        }, null, 10, _hoisted_168), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.SponsorSectionTitle]
                        ])
                      ]),
                      _createElementVNode("p", _hoisted_169, _toDisplayString(_ctx.$t("EventWebpage.SponsorDescription")), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_170, [
                    _createElementVNode("div", _hoisted_171, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowOrganizer) = $event)),
                        type: "checkbox",
                        disabled: _ctx.isMobileView
                      }, null, 8, _hoisted_172), [
                        [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowOrganizer]
                      ]),
                      _cache[74] || (_cache[74] = _createElementVNode("label", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_173, [
                      _createElementVNode("div", _hoisted_174, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.OrganizerSectionTitle) = $event)),
                          type: "text",
                          class: _normalizeClass([{'border-red': _ctx.ValidateInputField(_ctx.$t('Title'),_ctx.eventWebPageData.EventWebPagesMetaData.OrganizerSectionTitle === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowOrganizer)}, "form-control"]),
                          maxlength: _ctx.maxTitleLength,
                          placeholder: _ctx.$t('Organizer'),
                          disabled: _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowOrganizer)
                        }, null, 10, _hoisted_175), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.OrganizerSectionTitle]
                        ])
                      ]),
                      _createElementVNode("p", _hoisted_176, _toDisplayString(_ctx.$t("EventWebpage.OrganizerDescription")), 1),
                      _createVNode(_component_ImageContainer, {
                        "image-container-data": _ctx.eventPageOrganizerImageData,
                        onImageContainerClicked: _cache[57] || (_cache[57] = ($event: any) => (_ctx.ClickOnBackgroundImage(true,!_ctx.eventWebPageData.EventWebPagesMetaData.ShowOrganizer)))
                      }, null, 8, ["image-container-data"]),
                      _createElementVNode("div", _hoisted_177, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t("Description")), 1),
                        _createVNode(_component_Editor, {
                          description: _ctx.eventWebPageData.EventWebPagesMetaData.OrganizerDescription,
                          "is-read-only": _ctx.DisableFields(!_ctx.eventWebPageData.EventWebPagesMetaData.ShowOrganizer),
                          class: _normalizeClass({'border-red': _ctx.ValidateInputField(_ctx.$t('OrganizerImageOrDescription'),_ctx.IsOrganizerLogoOrDescriptionRequired(), _ctx.eventWebPageData.EventWebPagesMetaData.ShowOrganizer)}),
                          onUpdatedDescription: _ctx.SetOrganizerDescription
                        }, null, 8, ["description", "is-read-only", "class", "onUpdatedDescription"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_178, [
                    _createElementVNode("div", _hoisted_179, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[58] || (_cache[58] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.ShowFooter) = $event)),
                        type: "checkbox",
                        class: "cursor-notallowed",
                        disabled: true
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.eventWebPageData.EventWebPagesMetaData.ShowFooter]
                      ]),
                      _cache[75] || (_cache[75] = _createElementVNode("label", null, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_180, [
                      _createElementVNode("div", _hoisted_181, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventWebpage.FooterDescription")), 1),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.eventWebPageData.EventWebPagesMetaData.FooterText) = $event)),
                          type: "text",
                          maxlength: _ctx.maxInputFieldLength,
                          class: _normalizeClass(["form-control", {'border-red': _ctx.ValidateInputField(_ctx.$t('FooterText'),_ctx.eventWebPageData.EventWebPagesMetaData.FooterText === '', _ctx.eventWebPageData.EventWebPagesMetaData.ShowFooter)}]),
                          placeholder: _ctx.$t('EventWebpage.FooterPlaceholder'),
                          disabled: _ctx.isMobileView
                        }, null, 10, _hoisted_182), [
                          [_vModelText, _ctx.eventWebPageData.EventWebPagesMetaData.FooterText]
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            (_ctx.isMobileView)
              ? (_openBlock(), _createElementBlock("div", _hoisted_183, [
                  _createVNode(_component_WarningMessageBar, { "information-view-model": _ctx.informationMessage }, null, 8, ["information-view-model"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_184, [
                  _cache[76] || (_cache[76] = _createElementVNode("div", { class: "display-flex" }, [
                    _createElementVNode("div", { class: "width100" }, [
                      _createElementVNode("hr", { class: "footer-hr" })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_185, [
                    _createElementVNode("div", null, [
                      _createElementVNode("button", {
                        class: "btn btn-secondary btn-size-default btn-cancelevent",
                        disabled: !_ctx.isPageDirty,
                        onClick: _cache[60] || (_cache[60] = ($event: any) => (_ctx.Discard()))
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Discard")), 1)
                      ], 8, _hoisted_186)
                    ]),
                    (_ctx.isPageDirty)
                      ? (_openBlock(), _createBlock(_component_WarningMessageBar, {
                          key: 0,
                          "information-view-model": _ctx.warningMessage
                        }, null, 8, ["information-view-model"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_187, [
                      _createElementVNode("button", {
                        class: "btn btn-primary btn-size-default width100 btn-create-event",
                        disabled: !_ctx.isPageDirty,
                        onClick: _cache[61] || (_cache[61] = ($event: any) => (_ctx.Save()))
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Save")), 1)
                      ], 8, _hoisted_188)
                    ])
                  ])
                ]))
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_189, [
          _createVNode(_component_ImageProcess, {
            "module-name": _ctx.moduleName,
            "sub-module-name": _ctx.subModuleName,
            "image-src": _ctx.GetImageUrl(),
            "image-full-src": _ctx.GetImageUrl(),
            "featuredimage-src": _ctx.GetFeaturedImageUrl(),
            "has-featured-image": true,
            "show-featured-image": _ctx.showFeaturedImage,
            "only-show-feature-image": _ctx.showFeaturedImage,
            "is-full-image": true,
            onSelectBack: _ctx.ClickOnImage,
            onSetFeaturedImageIconUrl: _ctx.SetBackgroundImageUrl,
            onSetImageIconUrl: _ctx.SetlogoUrl,
            onDiscard: _ctx.SetLogoOldUrl,
            onDiscardFeature: _ctx.SetFeatureOldUrl
          }, null, 8, ["module-name", "sub-module-name", "image-src", "image-full-src", "featuredimage-src", "show-featured-image", "only-show-feature-image", "onSelectBack", "onSetFeaturedImageIconUrl", "onSetImageIconUrl", "onDiscard", "onDiscardFeature"])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}